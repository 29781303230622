export const Routing = {
  BusWorking: `/`,
  NewBooking: `/booking`,
  DirectBooking: `/`,
  // DirectBooking: `/direct-booking`,
  DialIvr: `/dial-ivr`,
  Setting: `/setting`,
  Reservation: `/reservation`,
  Passanger: `/Passenger`,
  EditBus:`/setting/bus/edit/:id`,
  AddBus:`/setting/bus/create`,
  EditCity:`/setting/city/edit/:id`,
  AddCity:`/setting/city/create`,
  EditAddress: `/setting/address/edit/:id`,
  AddAddress: `/setting/address/create`,
  EditReservation:`/reservation/edit/:id`,
  ViewReservation:`/reservation/view/:id`,
  BusManagement:`/bus-management`,
  RouteDetail:`/route`,
  Account:`/account`,
  BoardingPoint:`/boarding-point`,
  DropingPoint:`/droping-point`,
  Gallary:`/gallary`,
  ChartOpen:`/chart`,
  Login: `/login`,
  PassangerVerification: `/verify-ticket`,
  Driver: `/driver`,
  AddDriver: `/driver/create`,
  EditDriver: `/driver/edit/:id`,
  Route: `/route`,
  AddRoute: `/route/create`,
  EditRoute: `/route/edit/:id`,
  AddTravels: `/travels/create`,
  EditTravels: `/travels/edit/:id`,
  AddBusPair: `/buspair/create`,
  EditBusPair: `/buspair/edit/:id`,
  DirectBusList: `/buslist`,
  FreeBusListing: `/free-bus-listing`
}