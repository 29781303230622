import React, { lazy } from "react";
import { Routing } from "./constants";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Bounce, ToastContainer } from "react-toastify";
import { MainLayout } from "../Layout/MainLayout";

const BusWorking = lazy(() => import("../Home/BusWorking"));
const ReservationList = lazy(() => import("../Reservations/ReservationList"));
const ViewReservation = lazy(() => import("../Reservations/ViewReservation"));
const EditReservation = lazy(() => import("../Reservations/EditReservation"));
const EditAddress = lazy(() => import("../Settings/Address/EditAddress"));
const EditBus = lazy(() => import("../Settings/Bus/EditBus"));
const AddBus = lazy(() => import("../Settings/Bus/AddBus"));
const AddCity = lazy(() => import("../Settings/City/AddCity"));
const AddAddress = lazy(() => import("../Settings/Address/AddAddress"));
const EditCity = lazy(() => import("../Settings/City/EditCity"));
const Setting = lazy(() => import("../Settings/settings"));
const NewBooking = lazy(() => import("../NewBooking/NewBooking"));
const DirectBooking = lazy(() => import("../DirectBooking/DirectBooking"));
const Login = lazy(() => import("../Login/LoginRoute"));
const RouteDetail = lazy(() => import("../RouteDetails/RouteDetail"));
const BusManagement = lazy(() => import("../BusManagement/BusManagement"));
const PassangerVerification = lazy(() => import("../PassangerVerify/PassangerVerify"));
const DriverList = lazy(()=> import("../Driver/DriverList"));
const AddDriver = lazy(()=> import("../Driver/AddDriver"));
const EditDriver = lazy(()=> import("../Driver/EditDriver"));
const AddRoute = lazy(()=> import("../BusManagement/Route/AddRoute"));
const EditRoute = lazy(()=> import("../BusManagement/Route/EditRoute"));
const AddTravels = lazy(()=> import("../BusManagement/Travels/AddTravels"));
const EditTravels = lazy(()=> import("../BusManagement/Travels/EditTravels"));
const AddBusPair = lazy(()=> import("../BusManagement/BusPair/AddBusPair"));
const EditBusPair = lazy(()=> import("../BusManagement/BusPair/EditBusPair"));
const ChartOpen = lazy(()=> import("../BusManagement/ChartOpen/ChartOpen"));
const Account = lazy(()=> import("../Account/Account"));
const PassangerList = lazy(()=> import("../Passanger/PassangerList"));
const DialIvr = lazy(()=> import("../DirectBooking/DialIvr"));
const DirectBusList = lazy(()=> import("../DirectBooking/BusListDetail"));
const FreeBusListing = lazy(()=> import("../DirectBooking/FreeBusListing"));

const routes = [
  // {
  //   id: 0,
  //   path: Routing.BusWorking,
  //   component: BusWorking,
  //   isPrivateRoute: true,
  // },
  {
    id: 1,
    path: Routing.Reservation,
    component: ReservationList,
    isPrivateRoute: true,
  },
  {
    id: 2,
    path: Routing.ViewReservation,
    component: ViewReservation,
    isPrivateRoute: true,
  },
  {
    id: 3,
    path: Routing.EditReservation,
    component: EditReservation,
    isPrivateRoute: true,
  },
  {
    id: 4,
    path: Routing.EditAddress,
    component: EditAddress,
    isPrivateRoute: true,
  },
  {
    id: 5,
    path: Routing.EditCity,
    component: EditCity,
    isPrivateRoute: true,
  },
  {
    id: 6,
    path: Routing.EditBus,
    component: EditBus,
    isPrivateRoute: true,
  },
  {
    id: 7,
    path: Routing.Setting,
    component: Setting,
    isPrivateRoute: true,
  },
  {
    id: 8,
    path: Routing.NewBooking,
    component: NewBooking,
    isPrivateRoute: true,
  },
  {
    id: 9,
    path: Routing.AddBus,
    component: AddBus,
    isPrivateRoute: true,
  },
  {
    id: 10,
    path: Routing.AddCity,
    component: AddCity,
    isPrivateRoute: true,
  },
  {
    id: 11,
    path: Routing.AddAddress,
    component: AddAddress,
    isPrivateRoute: true,
  },
  {
    id: 12,
    path: Routing.BusManagement,
    component: BusManagement,
    isPrivateRoute: true,
  },
  {
    id: 13,
    path: Routing.RouteDetail,
    component: RouteDetail,
    isPrivateRoute: true,
  },
  {
    id: 14,
    path: Routing.BoardingPoint,
    component: BusWorking,
    isPrivateRoute: true,
  },
  {
    id: 15,
    path: Routing.DropingPoint,
    component: BusWorking,
    isPrivateRoute: true,
  },
  {
    id: 16,
    path: Routing.Gallary,
    component: BusWorking,
    isPrivateRoute: true,
  },
  {
    id: 17,
    path: Routing.ChartOpen,
    component: ChartOpen,
    isPrivateRoute: true,
  },
  {
    id: 18,
    path: Routing.Login,
    component: Login,
    isPrivateRoute: false,
  },
  {
    id: 19,
    path: Routing.PassangerVerification,
    component: PassangerVerification,
    isPrivateRoute: false,
  },
  {
    id: 20,
    path: Routing.Driver,
    component: DriverList,
    isPrivateRoute: true,
  },
  {
    id: 21,
    path: Routing.AddDriver,
    component: AddDriver,
    isPrivateRoute: true,
  },
  {
    id: 22,
    path: Routing.EditDriver,
    component: EditDriver,
    isPrivateRoute: true,
  },
  {
    id: 23,
    path: Routing.DirectBooking,
    component: DirectBooking,
    isPrivateRoute: false,
  },
  {
    id: 24,
    path: Routing.AddRoute,
    component: AddRoute,
    isPrivateRoute: true,
  },
  {
    id: 25,
    path: Routing.EditRoute,
    component: EditRoute,
    isPrivateRoute: true,
  },
  {
    id: 26,
    path: Routing.Account,
    component: Account,
    isPrivateRoute: true,
  },
  {
    id: 27,
    path: Routing.AddTravels,
    component: AddTravels,
    isPrivateRoute: true,
  },
  {
    id: 28,
    path: Routing.EditTravels,
    component: EditTravels,
    isPrivateRoute: true,
  },
  {
    id: 29,
    path: Routing.AddBusPair,
    component: AddBusPair,
    isPrivateRoute: true,
  },
  {
    id: 30,
    path: Routing.EditBusPair,
    component: EditBusPair,
    isPrivateRoute: true,
  },
  {
    id: 31,
    path: Routing.Passanger,
    component: PassangerList,
    isPrivateRoute: true,
  },
  {
    id: 32,
    path: Routing.DialIvr,
    component: DialIvr,
    isPrivateRoute: false,
  },
  {
    id: 33,
    path: Routing.DirectBusList,
    component: DirectBusList,
    isPrivateRoute: false,
  },
  {
    id: 34,
    path: Routing.FreeBusListing,
    component: FreeBusListing,
    isPrivateRoute: false,
  },
];

const AppRouting = () => {

  return (
    <Router>
      <ToastContainer key={1}
      position="top-center"
autoClose={1000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick={false}
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
transition={Bounce}/>
      <Routes>
        {routes.map((route, index) => {
          return (
            <React.Fragment key={route.id}>
              {route?.isPrivateRoute ? (
                <Route
                  path={route.path}
                  element={
                    <MainLayout>
                      <route.component />
                    </MainLayout>
                    }
                />
              ) : (
                <Route path={route.path} element={<route.component />} />
              )}
            </React.Fragment>
          );
        })}
      </Routes>
    </Router>
  );
};

export default AppRouting;
